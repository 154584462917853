import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/Seo';
import ThreeColCta from '../../../../components/ThreeColCta';

const HorizonApp = () => (
	<Layout>
		<Seo
			title="Download Thrive Hearing Control App for Starkey hearing aids | hear.com"
			description="Dowload the Thrive Hearing Control App for Starkey hearing aids by clicking here. Stay always connected with the Starkey hearing aids app."
		/>
		<div className="o-wrapper">
			<div className="o-row-2p5">
				<div className="c-newsletter-img">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png"
						alt="man sitting on couch and smiling"
						loading="lazy"
					/>
				</div>
			</div>
			<div className="o-row-2p5">
				<h1 className="c-content-hero__headline">Download Thrive Hearing Control App</h1>
			</div>
			<ul className="c-usps">
				<li>Thrive app: Controls hearing aids, adjusts volume, streams</li>
				<li>Healthable features: Goals, Engagement, Activity Scores</li>
				<li>Informative services: Translate, Transcribe, Self Check.</li>
			</ul>
			<div className="c-app-box">
				<a
					className="c-app-box__panel c-app-box__android"
					href="https://play.google.com/store/apps/details?id=com.starkey.android.newlink.release"
				>
					<p className="c-app-box__android-title">Android</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/android-small.png"
						alt=""
					/>
				</a>
				<a
					className="c-app-box__panel c-app-box__ios"
					href="https://apps.apple.com/us/app/thrive-hearing-control/id1322659659?ls=1"
				>
					<p className="c-app-box__ios-title">iOS</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/ios-small.png"
						alt=""
					/>
				</a>
			</div>
			<section className="c-app-section">
				<div className="o-row-2p5">
					<p className="c-app-section__title">Thrive Hearing Control App</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						The Thrive Hearing Control app for{' '}
						<a href="https://www.hear.com/hearing-aids/brands/starkey/">Starkey hearing aids</a> seamlessly integrates
						with sensor-enabled hearing aids, providing you with complete control over your hearing experience.
						Effortlessly change settings, adjust volume, create personalized memories, and effortlessly stream music or
						phone calls with just a tap.
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Beyond hearing control, the Thrive app offers Healthable features such as social engagement and activity
						tracking. Set goals, and monitor your progress daily through Engagement Score and Activity Score metrics.
						Additionally, the Thrive app provides informative services like Thrive Assistant, Translate, Transcribe, and
						Self Check, allowing you to assess your hearing aid performance without visiting an office.
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Compatible with hearing aids from Starkey, Audibel, NuEar, MicroTech, and AGX® Hearing, the Thrive app
						requires purchase through a hearing professional. Note that not all features are available across all
						hearing aid styles; consult your hearing professional for specifics.
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Please be aware that due to the varied capabilities of different Android phones for connecting and streaming
						audio to hearing aids, full compatibility of the Thrive app cannot be guaranteed with all phone models. For
						current compatibility information, please visit:
						https://www.starkey.com/hearing-aids/apps/thrive-hearing-control/compatibility
					</p>
				</div>
			</section>
			<div className="o-row-2p5">
				<p className="c-app-section__title">Resources</p>
			</div>
			<div className="o-row-2p5">
				<ThreeColCta
					ImgSrc1="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
					ContentTitle1="Hearing Test"
					ContentCopy1="Check how is your hearing in less than 5 minutes."
					Url1="/hearing-test/"
					ImgSrc2="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg"
					ContentTitle2="Hearing Loss"
					ContentCopy2="There are several types of hearing loss and many different cause."
					Url2="/hearing-loss/"
					ImgSrc3="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg"
					ContentTitle3="Hearing Aids"
					ContentCopy3="Connect you to the world around you with our wide range of devices."
					Url3="/hearing-aids/"
				/>
			</div>
		</div>
	</Layout>
);

export default HorizonApp;
